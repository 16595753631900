import React, {useState} from "react";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {useHistory} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {AdaButton} from "@adaming/ada-react-component";
import AssignementClient from "../components/assignment.client/assignement.client";
import AssignmentCollab from "../components/assignment.order/assignment.collab";
import AssignmentContractTypeClient from "../components/assignment.client/assignment.contractTypeClient";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../service/host";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

const context = "assignments";
export default function AssignementForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        control,
        unregister
    } = useForm();
    const [modalLoadingShow, setModalLoadingShow] = useState(false);
    const [edit, setEdit] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [assignment, setAssignment] = useState({});
    const [directClient, setDirectClient] = useState({});
    const [subClient, setSubClient] = useState({});
    const [showUndirect, setShowUndirect] = useState(false);
    const [selectedObjects, setSelectedObjects] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const [errorMessageClient, setErrorMessageClient] = useState("")
    const [errorMessageSubClient, setErrorMessageSubClient] = useState("")

    let history = useHistory();

    const cancelCreateAssignment = data => {
        history.push("/assignments");
    };

    const onSubmitAssignment = data => {
        setModalLoadingShow(true)
        console.log("data assignment : ", data);
        if (isValid) {
            if(!directClient.id || !(directClient.contacts && directClient.contacts.length>0) || !(directClient.contactsOp && directClient.contactsOp.length>0) || !(data.client.addressObj && data.client.addressObj.id)){
                setErrorMessageClient("champs obligatoires : Client, Adresse, Contact facturation et opérationnel")
                setModalLoadingShow(false)
                return;
            }

            if(data.subContracting === "true" && !subClient.id){
                setErrorMessageSubClient("champs obligatoires : Client")
                setModalLoadingShow(false)
                return;
            }

            let mappedData = mapData(data);
            console.log("Save assignment : ", mappedData);
            console.log(JSON.stringify(mappedData));
            service.post(getHostByAppName(ASSIGNMENT_NAME), `assignments`, callbackRes, callbackError, mappedData)
        } else {
            console.log("is not valid");
            setModalLoadingShow(false)
        }
    };

    const callbackRes = (res) => {

        console.log(res.data);
        setEdit(false);
        history.push(`/${context}/${res.data.id}/edit`);
    }

    const callbackError = (error) => {
        console.log("error to print", error);
        setErrorMessage(error.response.data.message)
        setModalLoadingShow(false)

    }

    const mapData = data => {
        console.log("assignment data", data);
        console.log("directClient object", directClient);
        console.log("subClient object", subClient);
        let dataTransforme = {
            organization: GetOrganizationIdFromSession(),
            employee: data.employee,
            responsible: data.responsible,
            commercial : data.commercial,
            termsOfPayment: directClient.termsOfPayment,
            directClient: {
                client: directClient.id,
                clientName: directClient.name,
                group: directClient.group,
                entity: directClient.entity,
                entityName: directClient.entityName,
                contacts: directClient.contacts !== undefined ? directClient.contacts : [],
                contactsOp: directClient.contactsOp !== undefined ? directClient.contactsOp : [],
                complementaryAddress: directClient.complementaryAddress,
                address: data.client.addressObj
            },
            subContractingClient: subClient.id !== undefined ? {
                client: subClient.id,
                clientName: subClient.name,
                group: subClient.group,
                entity: subClient.entity ? subClient.entity : "",
                entityName: subClient.entityName ? subClient.entityName : "",
                contacts: subClient.contacts !== undefined ? subClient.contacts : [],
                contactsOp: subClient.contactsOp !== undefined ? subClient.contactsOp : [],
                complementaryAddress: subClient.complementaryAddress,
                address: data.subContractingClient.addressObj
            } : null,
            reference: data.reference,
            beginAt: data.beginAt !== undefined ? data.beginAt.replace("T", " ") : null,
            endAt: data.endAt !== undefined ? data.endAt.replace("T", " ") : null,
            missionType: data.missionType,
            quantity: data.quantity,
            rates: data.rates !== undefined ? data.rates : [],
            discounts: data.discounts != undefined ? data.discounts : [],
            designation: data.designation,
            subContracting: data.subContracting,
            task: data.task
        };

        return dataTransforme;
    };

    const dispatch = (obj) => {
        console.log("selectItems values : ", selectedObjects)
        setSelectedObjects({...selectedObjects, ...obj});
    }


    return (
        <div>
            <Row>
                <Col className="col-sm-3">
                    <div>
                        <div className="display-4 grey--text">
                            <h4 className="mb-0 fs-22 bold-text" style={{color: "#FF0B0E"}}><IntlMessages
                                id="ass.create"/></h4>
                        </div>
                    </div>
                </Col>
            </Row>
            <br/>
            <form onSubmit={handleSubmit(onSubmitAssignment)}>
                <Row>
                    <Col className="col-sm-6">
                        <div className="display-5 c-text-danger">{errorMessage}</div>
                    </Col>
                </Row>
                <Row className="ptb-15">
                    <Col className="col-sm-12">
                        <AssignmentCollab
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            assignment={assignment}
                            edit={edit}
                        />
                    </Col>
                </Row>
                <div className="row ma-0 pb-3">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="assignment.client.type"/>
                            </div>
                            <div className="roe-card-body col-sm-12">
                                <Row className="col-sm-3">
                                    <Col>
                                        <AssignmentContractTypeClient
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            assignment={assignment}
                                            setShowUndirect={setShowUndirect}
                                            showUndirect={showUndirect}
                                            edit={edit}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ma-0">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style">
                            <div className="roe-card-header">
                                <span className="hash"># </span>
                                {!showUndirect && (<IntlMessages id="assignment.client.direct"/>)}
                                {showUndirect && (<IntlMessages id="assignment.client.undirect"/>)}
                            </div>
                            <div className="roe-card-body col-sm-12">
                                <Row>
                                    <Col className="col-sm-12">
                                        <AssignementClient dispatch={dispatch} selectedObjects={selectedObjects}
                                                           errors={errors}
                                                           register={register}
                                                           setValue={setValue}
                                                           reset={reset}
                                                           client={directClient}
                                                           setClient={setDirectClient}
                                                           edit={edit}
                                                           control={control}
                                                           clientType={"client"}
                                                           Controller={Controller} errorMessageClient={errorMessageClient}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

                {showUndirect && (
                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style">
                                <div className="roe-card-header">
                                    <span className="hash"># </span>
                                    <IntlMessages id="assignment.client.direct"/>
                                </div>
                                <div className="roe-card-body col-sm-12">
                                    <Row>
                                        <Col className="col-sm-12">
                                            <AssignementClient
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                control={control}
                                                client={subClient}
                                                setClient={setSubClient}
                                                edit={edit}
                                                clientType={"subContractingClient"}
                                                Controller={Controller} errorMessageClient={errorMessageSubClient}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <br/>
                <Row>
                    <Col className="col-sm-9"/>
                    <Col>
                        {edit && (
                            <FormGroup>
                                <AdaButton
                                    className="c-btn c-danger w-100"
                                    onClick={cancelCreateAssignment}
                                >
                                    <IntlMessages id="action.common.cancel"/>
                                </AdaButton>
                            </FormGroup>
                        )}
                    </Col>
                    <Col>
                        {edit && (
                            <FormGroup>
                                <AdaButton
                                    className="c-btn c-warning w-100"
                                    type="submit"
                                    onClick={() => setIsValid(true)}
                                    style={{marginLeft: 10}}
                                >
                                    <IntlMessages id="action.common.submit"/>
                                </AdaButton>
                            </FormGroup>
                        )}
                    </Col>

                </Row>
                <Modal
                    show={modalLoadingShow}
                    size="sm"
                    centered
                    backdrop={"static"}
                >
                    <div className="align-self-center ">
                        <Row className="pt-3">
                            <Col>
                                <Spinner animation="border" color="warning" role="status"
                                         style={{width: '5rem', height: '5rem'}}/>
                            </Col>
                        </Row>
                        <Row className="pt-3 pb-2"><Col>
                            <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                Chargement ...
                            </div>
                        </Col></Row>
                    </div>
                </Modal>
            </form>
        </div>
    );
}
