import {getEmployeeConnectedLight, getOrganizationSelected} from "@adaming/ada-react-component";


export function GetUserFromSession(){
    try {
        let empl = getEmployeeConnectedLight();
        return empl;
    } catch (error) {
        console.log(error)
        return {id:"5e171b0b079363412c83bda8", firstName:"Wajdi", lastName:"BELGUITH"}
    }
}

export function GetOrganizationIdFromSession(){
    try {
        let organization = getOrganizationSelected();
        return organization.id;
        //return "5e21d0e4dc885d391051803b";
    } catch (error) {
        console.log(error)
    }
}