import React, {useEffect, useRef, useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import axios from "axios";
import AssignmentContact from "./assignment.contact";
import * as service from "../../../../service/crud.service";
import * as personService from "../../../../service/employee.service";
import {CRM_NAME, getHostByAppName} from "../../../../service/host";

export default function AssignementClient({
                                              register,
                                              setValue,
                                              errors,
                                              reset,
                                              client, setClient,
                                              edit,
                                              clientType,
                                              Controller,
                                              control,
                                              errorMessageClient=""
                                          }) {
    const selectedEntity = useRef();
    const [clientList, setClientList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [contactList, setContactList] = useState([]);

    useEffect(
        () => {
            register({name: `${clientType}`});
            register({name: `${clientType}.entity`});
            register({name: `${clientType}.contacts`});
            register({name: `${clientType}.contactsOp`});
            register({name: `${clientType}.addressObj`});
            register({name: `${clientType}.complementaryAddress`});
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
        },
        []
    );

    const showAddress = result => {
        if (result !== undefined && result.length > 0) {
               let address = result[0].streetNumber +
                " " +
                result[0].streetName +
                " " +
                result[0].zipCode +
                " " +
                result[0].city
            setValue(`${clientType}.address`,address);
            setValue(`${clientType}.addressObj`,result[0]);
        }
    };


    useEffect(() => {
        /*List Client rest service */

    }, []);

    const handleChangeClient = selectedOption => {
        console.log(clientType, selectedOption);
        setContactList([]);
        if (selectedOption !== null) {
            setValue(`${clientType}`, selectedOption.object);
            setClient(selectedOption.object)
            service.get(getHostByAppName(CRM_NAME), `clients/${selectedOption.object.id}/address`, showAddress);

            setValue(`${clientType}.entity`, null);
            setValue(`${clientType}.contacts`, null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.object.id}`, setEntityList)
            personService.getAllContactSelect(getHostByAppName(CRM_NAME), `clients/contacts/${selectedOption.object.id}`, setContactList)
        } else {
            setClient({})
            setValue(`${clientType}.addressObj`,null);
            setEntityList([]);
            setContactList([]);
            setValue(`${clientType}`, null);
            setValue(`${clientType}.address`, "");
            setValue(`${clientType}.entity`, null);
            setValue(`${clientType}.contacts`, null);
            setValue(`${clientType}.contactsOp`, null);
        }
    };

    const handleChangeEntity = selectedOptionTab => {
        console.log("entity", selectedOptionTab);
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setClient({...client, entity: selectedOption.key, entityName:selectedOption.object.name,})
            setValue(`${clientType}.entity`, selectedOption.key);
            personService.getAllPersonSelect(getHostByAppName(CRM_NAME), `contacts/findBy/entity/${selectedOption.object.id}`, setContactList)
        } else {
            personService.getAllPersonSelect(getHostByAppName(CRM_NAME), `contacts/findBy/client/${client.id}`, setContactList)
            setClient({...client, entity: {}, factorizationContacts: {}, operationContacts: {}})
            setValue(`${clientType}.entity`, null);
            setValue(`${clientType}.contacts`, null);
            setValue(`${clientType}.contactsOp`, null);
        }
    };

    return (
        <div>
            <Row>
                <Col className="col-sm-6">
                    <div className="display-5 c-text-danger">{errorMessageClient}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="assignment.client"/>
                        <AdaSelect
                            isClearable
                            name={clientType}
                            errors={errors}
                            options={clientList}
                            onChange={handleChangeClient}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="assignment.entity"/>
                        <Controller
                            as={<AdaSelect name={`${clientType}.entity`}/>}
                            name={`${clientType}.entity`}
                            isClearable
                            errors={errors}
                            options={entityList}
                            onChange={handleChangeEntity}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="assignment.mission.address"/>
                        <AdaInputText
                            name={`${clientType}.address`}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="assignment.mission.address.complement"/>
                        <AdaInputText
                            name={`${clientType}.complementaryAddress`}
                            errors={errors}
                            innerRef={register}
                            onBlur={e => setClient({...client, complementaryAddress: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-6">
                    <AssignmentContact
                        control={control}
                        Controller={Controller}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        contactList={contactList} client={client} setClient={setClient}
                        edit={edit} clientType={clientType} contactType={"contacts"} name={"assignment.contact"}
                    />
                </Col>
                <Col className="col-sm-6">
                    <AssignmentContact
                        control={control}
                        Controller={Controller}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        contactList={contactList} client={client} setClient={setClient}
                        edit={edit} clientType={clientType} contactType={"contactsOp"} name={"assignment.contact.op"}
                    />
                </Col>
            </Row>
        </div>
    );
}
