import React, { useEffect, useState } from "react";
import { FormGroup, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import { AdaButton, AdaInputText, AdaSelect } from "@adaming/ada-react-component";
import * as specificService from "../../../service/employee.service";
import {
  ASSIGNMENT_NAME,
  CRM_NAME,
  EMPLOYEE_NAME,
  getHostByAppName,
  NOMENCLATURE_NAME
} from "../../../service/host";
import { Controller } from "react-hook-form";
import * as service from "../../../service/crud.service";

export default function OrderFacturation({
  register,
  setValue,
  errors,
  order,
  edit,
  control,
  dispatch
}) {
  const [billingAddOnsList, setBillingAddOnsList] = useState([]);
  const [billingAddSelectedList, setBillingAddOnsSelectedList] = useState([]);
  const [billingObj, setBillingObj] = useState({
    key: "",
    label: "",
    value: ""
  });
  const [messageFact, setMessageFact] = useState(null);

  useEffect(() => {
      register({name: "billingAddOns"});
    service.getAllForSelect(
      getHostByAppName(NOMENCLATURE_NAME),
      `billing_add_ons`,
        callBackBillingAddOns,
      error => console.log(error)
    );

  }, [order]);

  const callBackBillingAddOns = data =>{
      console.log("in order facturation",order)
      if(order && order.id && order.billingAddOns && order.billingAddOns.length > 0){
          setValue("billingAddOns", order.billingAddOns);
          setBillingAddOnsSelectedList(order.billingAddOns)
          setBillingAddOnsList(data.filter(objData => !order.billingAddOns.find(objSelected => objSelected.key === objData.key)))
      }else{
          setBillingAddOnsList(data)
      }
  }

  const handleChangeSelect = selectedOptionTab => {
    let selectedOption = selectedOptionTab[0];
    console.log("fact", selectedOption);
    if (selectedOption !== null) {
      setValue("fact", selectedOption.key);
      setBillingObj({
        ...billingObj,
        key: selectedOption.key,
        label: selectedOption.label
      });
    } else {
      setValue("fact", null);
    }
  };

  const handleChangeValue = data => {
    console.log("paramName, value", data.target.value);
    setBillingObj({ ...billingObj, value: data.target.value });
  };

  const addElement = () => {
    console.log("in add element", billingObj);
    if (billingObj.key && billingObj.value) {
      setBillingAddOnsList(
        billingAddOnsList.filter(obj => obj.key !== billingObj.key)
      );
      let list = billingAddSelectedList.concat(billingObj);
      setBillingAddOnsSelectedList(list);
      setBillingObj({ key: "", label: "", value: "" });
      setValue("fact", null);
      setValue("value", "");
      setValue("billingAddOns", list);
      setMessageFact(null);
    } else {
      setMessageFact("Champs obligatoire");
    }
  };

  const deleteElement = obj => {
    console.log("in delete element", obj);
    setBillingAddOnsList(billingAddOnsList.concat(obj));
    let list = billingAddSelectedList.filter(obj1 => obj1.key !== obj.key)
    setBillingAddOnsSelectedList(list);
      setValue("billingAddOns", list);
  };

  return (
    <div className="roe-card-body col-sm-12">
      {edit && <Row>
        <Col className="col-sm-4">
          <IntlMessages id="assignment.designation" />
          <Controller
            as={<AdaSelect name={"fact"} />}
            name={"fact"}
            isClearable
            errors={errors}
            options={billingAddOnsList}
            onChange={handleChangeSelect}
            control={control}
            innerRef={register}
          />
        </Col>

        <Col className="col-sm-4">
          <Row>
            <Col>
              <IntlMessages id="order.ext.reference" />
              <AdaInputText
                name="value" style={{height:38}}
                errors={errors}
                onChange={handleChangeValue}
                value={billingObj.value}
              />
            </Col>
            <Col className="mt-3">
              <Row >
                {edit &&<Col className="col-3">
                  <div className="c-btn ma-5 c-warning">
                    <i
                      className="fa fa-plus"
                      style={{ fontSize: 25 }}
                      onClick={addElement}
                    />
                  </div>
                </Col>}
                <Col className="col-8 mt-2">
                  <div className="display-7 c-text-danger">{messageFact}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>}
      <br />

      <Row>
        {billingAddSelectedList.map(obj => (
            <Col xs={4} className="mb-3">
              <Row className="col-sm-9">
                <Col className="col-sm-8 mt-1">
                  <span className="chip">
                      {obj.label} : {obj.value}
                    </span>
                </Col>
                <Col>
                  {edit && <div className="c-btn c-danger">
                    <i
                        className="fas fa-trash"
                        style={{ fontSize: 7}}
                        onClick={() => deleteElement(obj)}
                    />
                  </div>}
                </Col>
              </Row>

            </Col>

        ))}
      </Row>

    </div>
  );
}
