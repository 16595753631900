import Assignments from "../views/assignment/assignments"
import Orders from "../views/order/orders"
import OrderForm from "../views/order/create/new.order";
import OrderEditForm from "../views/order/edit/edit.order";
import AssignmentForm from "../views/assignment/create/new.assignment";
import AssignmentEditForm from "../views/assignment/edit/edit.assignment";
import Maintenance from "../views/pages/error/maintenance";

const assignmentRoutes = [
    {path: "/assignments", component: Assignments},
    {path: "/orders", component: Orders},
    {path: "/orders/new", component: OrderForm},
    {path: "/assignments/new", component: AssignmentForm},
    {path: "/assignments/:assignmentId/edit", component: AssignmentEditForm},
    {path: "/orders/:orderId/edit", component: OrderEditForm},
    {path: "/maintenance", component: Maintenance}

];

export default assignmentRoutes;