import React, {useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import CustomInput from "reactstrap/es/CustomInput";


const AssignmentContractTypeClient = ({ showUndirect, setShowUndirect,register, errors, assignment}) => {

    return (
        <div className="ptb-5">
            <Row>
                <Col className="mt-2">
                    <FormGroup>
                        <CustomInput
                            id="id3"
                            type="radio"
                            name="subContracting"
                            value={false}
                            defaultChecked={true}
                            onClick={() => setShowUndirect(false)}
                            label={
                                <IntlMessages id="assignment.client.direct"/>
                            }
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col className="mt-2">
                    <FormGroup>
                        <CustomInput
                            id="id4"
                            type="radio"
                            name="subContracting"
                            value={true}
                            onClick={() => setShowUndirect(!showUndirect)}
                            label={
                                <IntlMessages id="assignment.client.undirect"/>
                            }
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default AssignmentContractTypeClient;