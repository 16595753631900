import React, {useEffect, useState} from "react";
import {
    FormGroup,
    Label,
    Row,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import SweetAlert from "react-bootstrap-sweetalert";
import VisualizeDocumentAction from "../../actions/visualize.document";
import SignAction from "../../actions/sign.action";
import SendAction from "../../actions/send.action";
import UploadAction from "../action.upload";
import {useHistory} from "react-router-dom";
import * as service from "../../../../service/crud.service";
import {ASSIGNMENT_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../../../service/host";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import CloseAction from "../../actions/close.action";

export default function AssignmentAction({
                                             setEdit,
                                             assignment,
                                             setAssignment,
                                             setErrorMessage,
                                             context, reload
                                         }) {
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    const [modalLoadingShow, setModalLoadingShow] = useState(false);
    const [operation, setOperation] = useState("");
    const [objectToPdf, setObjectToPdf] = useState({})
    let history = useHistory();

    const onConfirm = () => {
        reload();
        setModalSuccessShow(false);
    }

    useEffect(() => {

    }, [assignment])

    const mapAssignment = data => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit" };

        return {
            ...data,
            designation: data.designation,
            beginAt: new Date(data.beginAt).toLocaleTimeString("fr-FR", options),
            endAt: data.endAt ? new Date(data.endAt).toLocaleTimeString("fr-FR", options) : "N.D",
            tjmDate: data.tjmDate,
            tjm: data.tjm
        }
    }

    const loadInformationByAssignment = async assignmentId => {
        let assignmentTemp = {}
        let employeeTemp = {}
        let responsibleTemp = {}
        let addressTemp = {}
        let organizationTemp = {}
        let contactList = []
        let rate = {}
        let discount = {}


        await service.getUri(`${getHostByAppName(ASSIGNMENT_NAME)}/rates/findBy/assignment/${assignment.id}`)
            .then(res => rate = res.data.length > 0 ? res.data[res.data.length - 1] : {})

        await service.getUri(`${getHostByAppName(ASSIGNMENT_NAME)}/discounts/findBy/assignment/${assignment.id}`)
            .then(res => discount = res.data.length > 0 ? res.data[res.data.length - 1] : {})

        assignmentTemp = {
            ...assignment,
            tjmDate: rate && rate.beginAt ? rate.beginAt : "",
            tjm: rate && rate.rate ? rate.rate : "",
            discount:discount && discount.quantity ? discount.quantity : "0"
        }


        await service.getUri(`${getHostByAppName(ORGANIZATION_NAME)}/organizations/${assignmentTemp.organization}`)
            .then(res => organizationTemp = res.data)

        if (assignmentTemp.employee) {
            await service.getUri(`${getHostByAppName(EMPLOYEE_NAME)}/employees/${assignmentTemp.employee}`)
                .then(res => employeeTemp = {name: res.data.firstName + ' ' + res.data.lastName, email: res.data.contactInformation.email})
        }
        if (assignmentTemp.responsible) {
            await service.getUri(`${getHostByAppName(EMPLOYEE_NAME)}/employees/${assignmentTemp.responsible}`)
                .then(res => responsibleTemp = {name: res.data.firstName + ' ' + res.data.lastName, email: res.data.contactInformation.email})
        } else {
            responsibleTemp = {name: "Fawzi OUARETH", email: "fouareth@adaming.fr"};
        }

        await service.getUri(`${getHostByAppName(CRM_NAME)}/clients/${assignmentTemp.directClient.client}/address`)
            .then(res => addressTemp = res.data && res.data.length > 0 ? res.data[0] : {})

        if (assignmentTemp.directClient !== undefined && assignmentTemp.directClient.contacts !== null && assignmentTemp.directClient.contacts.length > 0) {
            for (const contactId of assignmentTemp.directClient.contacts) {
                await service.getUri(`${getHostByAppName(CRM_NAME)}/contacts/${contactId}`)
                    .then(res => contactList.push(res.data))
            }
        }

        console.log("ojectToPrint", {
            ...mapAssignment(assignmentTemp),
            transmitter: organizationTemp,
            employee: employeeTemp,
            responsible: responsibleTemp,
            clientContacts: contactList,
            address: addressTemp
        })

        return {
            ...mapAssignment(assignmentTemp),
            transmitter: organizationTemp,
            employee: employeeTemp,
            responsible: responsibleTemp,
            clientContacts: contactList,
            address: addressTemp
        }
    }


    return (
        <div>

            <div className="container">
                <div className="row">
                    <div className="col align-self-end">
                        <ButtonGroup className="align-items-end">
                            <div>
                                <AdaButton style={{height: 32, width:120}} className="c-btn ma-5 no-border c-outline-primary" disabled={!assignment.enable}
                                           onClick={() => setEdit(true)}>
                                    <i className="fas fa-edit" style={{fontSize: 12}}> Modifier</i>
                                </AdaButton>
                            </div>

                            <VisualizeDocumentAction setModalLoadingShow={setModalLoadingShow}
                                                     context={context}
                                                     setErrorMessage={setErrorMessage}
                                                     loadInformationByAssignment={loadInformationByAssignment}
                            />

                            <SignAction
                                context={context}
                                objectResource={assignment}
                                setObjectResource={setAssignment}
                                setModalSuccessShow={setModalSuccessShow}
                                setOperation={setOperation}
                                setErrorMessage={setErrorMessage}
                                operation={operation}
                            />

                            {context ==="assignments" && <SendAction
                                context={context}
                                objectResource={assignment}
                                setObjectResource={setAssignment}
                                setModalSuccessShow={setModalSuccessShow}
                                setErrorMessage={setErrorMessage} loadInformationByAssignment={loadInformationByAssignment} setModalLoadingShow={setModalLoadingShow}
                            />}

                            <UploadAction objectResource={assignment} setObjectResource={setAssignment}
                                          setModalSuccessShow={setModalSuccessShow}
                                          setErrorMessage={setErrorMessage} context={context}
                            />

                            <CloseAction
                                context={context}
                                objectResource={assignment}
                                setObjectResource={setAssignment}
                                setModalSuccessShow={setModalSuccessShow}
                                setErrorMessage={setErrorMessage}
                            />

                        </ButtonGroup>
                    </div>
                </div>
            </div>

            <SweetAlert
                success
                show={modalSuccessShow}
                title="Succès !"
                onConfirm={() => onConfirm()}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
            >
                <IntlMessages id="action.common.succes"/>
            </SweetAlert>

            <Modal
                show={modalLoadingShow}
                size="sm"
                centered
                backdrop={"static"}
            >
                <div className="align-self-center ">
                    <Row className="pt-3">
                        <Col>
                            <Spinner animation="border" color="warning" role="status"
                                     style={{width: '5rem', height: '5rem'}}/>
                        </Col>
                    </Row>
                    <Row className="pt-3 pb-2"><Col>
                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                            Chargement ...
                        </div>
                    </Col></Row>
                </div>
            </Modal>


        </div>
    );
}
