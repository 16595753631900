import React, {useEffect, useState} from "react";
import {AdaButton} from "@adaming/ada-react-component";
import {Col, Row, Table} from "react-bootstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import AssignmentAddLineDiscountModal from "./assignment.addLineDiscountModal";
import * as service from "../../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../../service/host";

export default function AssignmentDiscountPriceTable({
                                                         register,
                                                         setValue,
                                                         errors,
                                                         assignment, handlerSave,
                                                         edit, details, related="assignment"
                                                     }) {
    const [modalAddLineShows, setModalAddLineShow] = React.useState(false);
    const [lines, setLines] = useState([]);

    useEffect(() => {
        register({name: "discounts"});
        if (assignment.id !== undefined) {
            getAllLines();
        }
    }, [assignment]);

    const errorMessage = error => {
    }

    const getAllLines = () => {
        service.get(
            getHostByAppName(ASSIGNMENT_NAME),
            `discounts/findBy/${related}/${assignment.id}`,
            setLines,
            errorMessage
        );
    }

    const handlerSaveAddLine = data => {
        console.log("data line : ", data);
        let line = {
            beginAt: data.beginAtDiscount !== undefined ? data.beginAtDiscount : null,
            quantity: Math.round(data.discount),
            enable: true
        };
        let lineToSave = lines.concat(line);
        if (assignment.id !== undefined) {
            handlerSaveLine({...line, relatedTo : assignment.id});
        } else {
            setLines(lineToSave);
            setValue("discounts", lineToSave);
        }

    };

    const handlerSaveLine = line => {
        service.post(getHostByAppName(ASSIGNMENT_NAME), `discounts`, getAllLines, ((error) => {
            console.log(error)
        }), line)
    }

    const handlerDeleteLine = line => {
        service.deletes(getHostByAppName(ASSIGNMENT_NAME), `discounts`, getAllLines, ((error)=>{console.log(error)}), [line.id])
    }

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        let lineToDelete = {}
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }else{
                lineToDelete = value;
            }
        });
        if (assignment.id !== undefined) {
            handlerDeleteLine(lineToDelete);
        } else {
            setValue("discounts", arr);
        }
        return arr;
    };


    return (
        <div>
            <Row>
                <Col className="col-sm-7 ">
                    <IntlMessages id="assignment.discount"/>
                    {!details && <AdaButton
                        className="c-add-btn ml-sm-2 mb-2"
                        onClick={() => setModalAddLineShow(true)}
                    >
                        <i className="fas fa-plus"/>
                    </AdaButton>}

                </Col>
            </Row>
            <Row>
                <Col>
                    <Table hover responsive bordered striped>
                        <thead align="left">
                        <tr>
                            {edit && <th width="5%"/>}
                            <th width="20%">
                                <IntlMessages id="assignment.date.start"/>
                            </th>
                            <th width="20%">
                                <IntlMessages id="assignment.discounts"/>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {lines !== undefined && lines !== null &&
                        lines.map((line, key) => (
                            <tr key={key}>
                                {edit && (
                                    <td>
                                        <AdaButton
                                            className="c-danger"
                                            onClick={() =>
                                                setLines(deleteElementFromList(key, lines))
                                            }
                                        >
                                            <i className="fas fa-trash"/>
                                        </AdaButton>
                                    </td>
                                )}
                                <td>{line.beginAt}</td>
                                <td>{line.quantity} J</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <AssignmentAddLineDiscountModal
                show={modalAddLineShows}
                onHide={() => setModalAddLineShow(false)}
                processActions={handlerSaveAddLine}
            />
        </div>
    );
}
