import React, {useEffect, useState} from "react";
import * as service from "../../../../service/crud.service";
import {
    EMPLOYEE_NAME,
    ORGANIZATION_NAME,
    getHostByAppName
} from "../../../../service/host";

import {person} from "../../../../helpers/constants"
import LatestActivity from "../UserInfoDoughnutWidget";

export default function AssignmentDisplayCollab({assignment}) {
    const [employee, setEmployee] = useState({});
    const [responsible, setResponsible] = useState({});
    const [organization, setOrganization] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(
        () => {
            if (assignment !== undefined && assignment.id !== undefined) {
                if (assignment.employee !== undefined && assignment.employee !== null) {
                    service.getById(
                        getHostByAppName(EMPLOYEE_NAME),
                        `employees`,
                        setEmployee,
                        setErrorMessage,
                        assignment.employee
                    );
                }
            }
        },
        [assignment]
    );

    return (
        <div className="ptb-5">

            <LatestActivity
                icon={person}
                title={employee && employee.id ? (employee.civility ? employee.civility+". " : "" )+employee.firstName+" "+employee.lastName : ""}
                email={employee.contactInformation && employee.contactInformation.email ? employee.contactInformation.email : "ND"}
                telephone={employee.contactInformation !== undefined ? employee.contactInformation.mobile : "ND"}
                statut={employee.enable === true ? "Activé" : "Désactivé"}
                categ={employee.category}
                func={employee.function}
                organ={assignment.organization}
                secnum={employee.socialSecurityNumber ? employee.socialSecurityNumber :"ND"}
                res={assignment.responsible}
                comm={assignment.commercial}
            />

            {/*




            <Row className="pt-2">
                <Col>
                    <div>
                        <span className="hash"># </span>{" "}
                        <IntlMessages id={`action.employee`}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Toast style={{maxWidth: "500px"}}>
                        <ToastHeader>
                            <div className="display-6 c-text-warning">
                                {employee !== undefined
                                    ? employee.firstName +
                                    " " +
                                    employee.lastName
                                    : ""}
                            </div>
                        </ToastHeader>
                        <ToastBody>
                            <Row>
                                <Col>
                  <span className="chip" style={{width: "110px"}}>
                    <IntlMessages id="assignment.contact.email"/>
                  </span>
                                    {employee.contactInformation !== undefined
                                        ? " : " + employee.contactInformation.email
                                        : ""}
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col>
                  <span className="chip" style={{width: "110px"}}>
                    <IntlMessages id="common.telephone"/>
                  </span>
                                    {employee.contactInformation !== undefined
                                        ? " : " +
                                        employee.contactInformation.mobile +
                                        " / " +
                                        employee.contactInformation.phone
                                        : ""}
                                </Col>
                            </Row>
                        </ToastBody>
                    </Toast>
                </Col>
            </Row>

            <Row className="pt-2">
                <Col>
                    <Toast style={{maxWidth: "500px"}}>
                        <ToastHeader>
                            <div className="display-6 c-text-warning">
                                {organization !== undefined ? organization.name : ""}
                            </div>
                        </ToastHeader>
                        <ToastBody>
                            <Row>
                                <Col>
                  <span className="chip" style={{width: "110px"}}>
                    <IntlMessages id="assignment.responsible"/>
                  </span>

                                    {responsible !== undefined
                                        ? " : " + responsible.firstName + " " + responsible.lastName
                                        : ""}
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col>
                  <span className="chip" style={{width: "110px"}}>
                    <IntlMessages id="assignment.contact.email"/>
                  </span>
                                    {responsible.contactInformation !== undefined
                                        ? " : " + responsible.contactInformation.email
                                        : ""}
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col>
                  <span className="chip" style={{width: "110px"}}>
                    <IntlMessages id="common.telephone"/>
                  </span>
                                    {responsible.contactInformation !== undefined
                                        ? " : " +
                                        responsible.contactInformation.mobile +
                                        " / " +
                                        responsible.contactInformation.phone
                                        : ""}
                                </Col>
                            </Row>
                        </ToastBody>
                    </Toast>
                </Col>
            </Row>*/}
        </div>
    );
}
