import React, {useEffect, useState} from "react";

import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../service/host";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";

export default function CloseAction({
                                       setObjectResource,
                                       setModalSuccessShow,
                                       objectResource,
                                       setOperation,
                                       setErrorMessage,
                                       operation,
                                       context
                                   }) {
    const [close, setClose] = useState(false);
    const [errorMessageClose, setErrorMessageClose] = useState("");
    const [dateFin, setDateFin] = useState("")


    const handlerClickSign = () => {
        //setOperation("Cloturer le contrat");
        setClose(true);
    };

    const callbackRes = (res) => {
        console.log(res.data);
        setClose(false);
        setObjectResource(res.data);
        setModalSuccessShow(true);
    }

    const callbackError = (error) => {
        console.log("error to print", error.response.data.message);
        setErrorMessageClose(error.response.data.message);
    }

    const onSubmitClose = () => {
    console.log("date fin",dateFin)
        if(!dateFin){
            setErrorMessageClose("Merci de mentionner la date fin")
            return;
        }
        service.put(
            getHostByAppName(ASSIGNMENT_NAME),
            `${context}/close/${objectResource.id}/${dateFin}`,
            callbackRes,
            callbackError
        );
    };

    return (
        <div>
            <AdaButton disabled={!objectResource.enable} style={{height: 32, width:120}}
                className="c-btn ma-5 no-border c-outline-primary"
                onClick={() => handlerClickSign()}
            >
                <i className="far fa-times-circle" style={{fontSize: 12}}> Fermer </i>
            </AdaButton>

            <SweetAlert
                style={{height: 330}}
                warning
                showCancel
                show={close}
                confirmBtnText="Oui"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={operation}
                onConfirm={() => onSubmitClose()}
                onCancel={() => setClose(false)}
            >
                <IntlMessages id="action.common.confirmation"/>

                <Row className="pt-3">
                    <Col >
                        <FormGroup>
                            <IntlMessages id="assignment.date.end"/>
                            <AdaInputText
                                type="date"
                                name="endAt"
                                onBlur={e => setDateFin(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <div className="display-5 c-text-danger">{errorMessageClose}</div>
                        </FormGroup>
                    </Col>
                </Row>
            </SweetAlert>
        </div>
    );
}
