import React, {useEffect, useState} from "react";
import {AdaSelect} from "@adaming/ada-react-component";
import {Col, Row} from "react-bootstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {FormGroup} from "reactstrap";

export default function AssignmentContact({
                                              register,
                                              setValue,
                                              errors,
                                              contactList,
                                              contactType,
                                              clientType, Controller, control,client, setClient,name
                                          }) {

    const [selectedContacts, setSelectedContacts] = React.useState({
        selectedOption: []
    });

    const handleChangeContact = selectedOption => {
        console.log(`${clientType}.${contactType}`, selectedOption);
        if (selectedOption !== null) {
            let tab = selectedOption.map(obj => obj.key);
            if(contactType === "contacts"){
                setClient({...client, contacts : tab})
            }else{
                setClient({...client, contactsOp : tab})
            }
            setValue(`${clientType}.${contactType}`, tab);
        } else {
            if(contactType === "contacts"){
                setClient({...client, contacts : []})
            }else{
                setClient({...client, contactsOp : []})
            }
            setValue(`${clientType}.${contactType}`, null);
        }
    };

    return (
        <div>
            <Row>
                <Col className="col-sm-12 ">
                    <FormGroup>
                        <IntlMessages id={`${name}`}/>
                        <Controller
                            as={<AdaSelect
                            />}
                            isClearable
                            isMulti
                            name={`${clientType}.${contactType}`}
                            errors={errors}
                            options={contactList}
                            onChange={selectedElement => handleChangeContact(selectedElement[0])}
                            control={control}
                            innerRef={register}
                        />

                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
