import React from "react";
import axios from "axios";
import {getConfigObject} from "@adaming/ada-react-component";


export const getAllPersonSelect = (host, context, setState, state) => {
    let listForSelect = [];
    console.log("get ", context);
    axios.get(`${host}/${context}/`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.firstName + " " + item.lastName,
                    value: item.id,
                    object: item
                })
            );
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};

export const getAllContactSelect = (host, context, setState, state) => {
    let listForSelect = [];
    console.log("get ", context);
    axios.get(`${host}/${context}/`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
            {
                if(item.listContacts !== undefined && Array.isArray(item.listContacts) && item.listContacts.length > 0){
                    item.listContacts.forEach(contact =>{
                        listForSelect.push({
                            key: contact.id,
                            label: contact.firstName + " " + contact.lastName,
                            value: contact.id,
                            object: contact
                        })
                    })
                }

            }

            );
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};

const comparerBeginDate = (a, b) => {
    const dateA = new Date(a.object.beginAt);
    const dateB = new Date(b.object.beginAt);
    return  dateB - dateA;
}

export const getAllResourcesSelect = (host, context, setState, state) => {
    let listForSelect = [];
    axios.get(`${host}/${context}`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.beginAt.split(' ')[0] + " : " + item.designation,
                    value: item.id,
                    object: item
                })
            );
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }

            listForSelect.sort(comparerBeginDate);
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};

export const getAllAssignementsForEmployee = (host, context, idEmployee, setState, state) => {
    let configObject = getConfigObject();
    const search = {
        transmitter: configObject.headers.organization,
        employee: idEmployee
    }
    axios.put(`${host}/${context}`, search, configObject).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            let listForSelect = [];
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.beginAt.split(' ')[0] + " : " + item.designation,
                    value: item.id,
                    object: item
                })
            );
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }

            listForSelect.sort(comparerBeginDate);
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
}
