import IntlMessages from "../../../helpers/intlMessages";
import React from "react";

export const columnsAssignments = [
    {
        name: <IntlMessages
            id="order.reference"
        />,
        selector: "code",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="assignment.mission.designation"
        />,
        selector: "designation",
        width: "40%",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="assignment.client"
        />,
        selector: "directClient.clientName",
        width: "20%",
        sortable: false,
        filtrable: true,
        format: row => (row.directClient.entityName ? row.directClient.clientName+"/"+row.directClient.entityName : row.directClient.clientName)
    },
    {
        name: <IntlMessages
            id="assignment.date"
        />,
        selector: "beginAt",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="assignment.date.end"
        />,
        selector: "endAt",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="employee.statut"
        />,
        selector: "enable",
        sortable: false,
        filtrable: true,
        format: row => (row.enable  ? "En cours" : "Fermé")
    },

];

