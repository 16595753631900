import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import Col from "reactstrap/es/Col";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../helpers/intlMessages";

import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Row
} from "reactstrap";
import Modal from "react-bootstrap/Modal";
import SendMailForm from "../components/assignment.consultation/send.form";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../service/host";

export default function SendAction({
                                       setObjectResource,
                                       objectResource,
                                       setModalSuccessShow,
                                       setErrorMessage,
                                       context, loadInformationByAssignment, setModalLoadingShow
                                   }) {
    const [send, setSend] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const callbackRes = (res) => {
        console.log(res.data);
        setObjectResource(res.data);
        setModalLoadingShow(false)
        setModalSuccessShow(true);
    }

    const callbackError = (error) => {
        setErrorMessage(error.message);
        console.log("error to print", error.message);
    }

    const onIsSent = () => {
        console.log("in is sent invoice ");

        service.put(
            getHostByAppName(ASSIGNMENT_NAME),
            `${context}/issent/${objectResource.id}/`,
            callbackRes,
            callbackError
        );

    };

    const sendByEmail = async () => {
        setModalLoadingShow(true)
        let objectResource = await loadInformationByAssignment();
        service.put(
            getHostByAppName(ASSIGNMENT_NAME),
            `${context}/send/${objectResource.id}/`,
            callbackRes,
            callbackError, {destinationName: objectResource.employee.name, destination: objectResource.employee.email,ccDestinations: objectResource.responsible.email, object: JSON.stringify(objectResource)}
        );

    }

    return (
        <div>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle style={{height: 32, width:120}} className="c-btn ma-5 no-border c-outline-primary">
                    <i className="far fa-envelope" style={{fontSize: 12}}> Envoyer</i>
                </DropdownToggle>
                <DropdownMenu>
                    {/*<DropdownItem onClick={() => setSend(true)}>Par mail</DropdownItem>*/}
                    <DropdownItem onClick={()=> sendByEmail()}>Par Email</DropdownItem>
                    <DropdownItem onClick={() => onIsSent()}>
                        Marquer comme 'Envoyé'
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <Modal
                show={send}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <IntlMessages id="send.document"/> :{" "}
                        <IntlMessages id="num"/> {objectResource.id}
                    </Modal.Title>
                </Modal.Header>
                <div>
                    <Row className="col-sm-12">
                        <Col className="col-sm-12">
                            <br/>
                            <SendMailForm
                                setModalSuccessShow={setModalSuccessShow}
                                setObjectResource={setObjectResource}
                                objectResource={objectResource}
                                setSend={setSend}
                                context={context}
                            />
                        </Col>
                    </Row>
                </div>
                <Modal.Footer/>
            </Modal>
        </div>
    );
}
