import React, {useEffect, useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import CustomInput from "reactstrap/es/CustomInput";
import {AdaInputText} from "@adaming/ada-react-component";

const AssignmentContractTypeOrder = ({
                                         register,
                                         setValue,
                                         errors,
                                         assignment,
                                         edit
                                     }) => {
    const [showEndDate, setShowEndDate] = useState(true);

    useEffect(() => {
        register({name: "missionType"});
        register({name: "beginAt"}, {required: "Ce champ est obligatoire."});
        register({name: "endAt"});
        register({name: "quantity"});
    }, []);

    return (
        <div className="pl-2">
            <Row className="col-sm-5">
                <Col className="mt-2">
                    <FormGroup>
                        <CustomInput
                            id="id1"
                            type="radio"
                            name="missionType"
                            defaultChecked={true}
                            label={<IntlMessages id="assignment.determined"/>}
                            onClick={() => setShowEndDate(true)}
                            errors={errors}
                            innerRef={register}
                            value="determined"
                        />
                    </FormGroup>
                </Col>
                <Col className="mt-2">
                    <FormGroup>
                        <CustomInput
                            id="id2"
                            type="radio"
                            name="missionType"
                            label={<IntlMessages id="assignment.undetermined"/>}
                            onClick={() => setShowEndDate(false)}
                            errors={errors}
                            innerRef={register}
                            value="undetermined"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="pl-2 pt-1">
                <Col className="col-sm-6">
                    <FormGroup>
                        <IntlMessages id="assignment.date.start"/>
                        <AdaInputText
                            type="datetime-local"
                            name="beginAt"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                {showEndDate && (
                    <Col className="col-sm-6">
                        <FormGroup>
                            <IntlMessages id="assignment.date.end"/>
                            <AdaInputText
                                type="datetime-local"
                                name="endAt"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                )}
                {/*{showEndDate && (
                    <Col className="col-sm-4">
                        <FormGroup>
                            <IntlMessages id="assignment.nbDays"/>
                            <AdaInputText
                                type="number"
                                name="quantity"
                                defaultValue={
                                    assignment !== undefined ? assignment.quantity : ""
                                }
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                )}*/}
            </Row>
        </div>
    );
};

export default AssignmentContractTypeOrder;
