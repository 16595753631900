import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row, CustomInput, DropdownToggle} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {paymentMethod} from "../../../commun/enum/paymentMethod";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import * as service from "../../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../../service/host";


export default function SendMailForm({
                                         setObjectResource,
                                         objectResource,
                                            setSend,
                                            setModalSuccessShow, context
                                        }) {
    const [errorMessagePayment, setErrorMessagePayment] = useState("");
    const {register, handleSubmit, errors, setValue} = useForm();


    useEffect(() => {
        register(
            {name: "transmitter"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "destination"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "ccDestinations"}
        );
        register(
            {name: "cciDestinations"}
        );

        register(
            {name: "object"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "message"},
            {required: "Ce champ est obligatoire."}
        );

    }, []);


    const callbackRes = (res) => {
        console.log(res.data);
        setSend(false);
        setObjectResource(res.data);
        setModalSuccessShow(true);
    }

    const callbackError = (error) => {
        setErrorMessagePayment(error.message);
        console.log("error to print", error.message);
    }

    const onSubmitSend = data => {
        console.log("in submit send mail ", data);
        service.put(
            getHostByAppName(ASSIGNMENT_NAME),
            `${context}/send/${objectResource.id}/`,
            callbackRes,
            callbackError,
            {
                ...data, documents : []
            }
        );
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitSend)}>
                <Row>
                    <Col>
                        <div className="display-5 c-text-danger">
                            {errorMessagePayment}{" "}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="send.transmitter"/>
                            <AdaInputText
                                name="transmitter"
                                defaultValue={"wbelguith@adaming.fr"}
                                type="text"
                                errors={errors}
                                innerRef={register}
                                disabled
                            />
                        </FormGroup>
                    </Col>
                    <Col style={{"margin-top": "25px"}}>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id="receiveCopy"
                                name="receiveCopy"
                                defaultChecked={true}
                                innerRef={register}
                                label={<IntlMessages id="receive.copy"/>}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="send.destination"/>
                            <AdaInputText
                                name="destination"
                                type="text"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="send.destination.cc"/>
                            <AdaInputText
                                name="ccDestinations"
                                type="text"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="send.destination.cci"/>
                            <AdaInputText
                                name="cciDestinations"
                                type="text"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="object"/>
                            <AdaInputText
                                name="object"
                                defaultValue={"Ordre N° :" + objectResource.id}
                                type="text"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="send.pj"/>
                            <Row>
                                <Col>
                                    <div className="c-btn ma-5 c-danger">
                                        <i className="far fa-window-close"/>
                                    </div>
                                    Facture.pdf
                                </Col>
                                <Col>
                                    <div className="c-btn ma-5 c-danger">
                                        <i className="far fa-window-close"/>
                                    </div>
                                    avoir.pdf
                                </Col>
                                <Col>
                                    <div className="c-btn ma-5 c-danger">
                                        <i className="far fa-window-close"/>
                                    </div>
                                    cra.pdf
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="send.message"/>
                            <AdaInputText
                                name="message"
                                type="textarea"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="col-sm-12">
                    <Col className="col-sm-8"/>
                    <Col>
                        <FormGroup>
                            <AdaButton onClick={() => setSend(false)}>
                                <IntlMessages id="action.common.cancel"/>
                            </AdaButton>
                            <AdaButton type="submit" style={{marginLeft: 10}}>
                                <IntlMessages id="action.common.submit"/>
                            </AdaButton>
                        </FormGroup>
                    </Col>
                </Row>
            </form>
        </div>
    );
}
