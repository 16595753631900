import React, {useEffect, useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputTextArea, AdaSelect} from "@adaming/ada-react-component";
import axios from "axios";
import * as service from "../../../../service/crud.service";
import * as employee_service from "../../../../service/employee.service";
import { getHostByAppName, EMPLOYEE_NAME, IAM_NAME} from "../../../../service/host";

export default function AssignmentOrganization({
                                                   register,
                                                   setValue,
                                                   errors,
                                                   assignment,
                                                   edit
                                               }) {
    const [organizationList, setOrganizationList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [responsiblePersonList, setResponsiblePersonList] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [commercialPersionList, setCommercialPersonList] = useState([]);
    const [selectedCommercial, setSelectedCommercial] = useState();
    const [selectedCommercialDisabled, setSelectedCommercialDisabled] = useState(true);


    useEffect(() => {
        register({name: "employee"});
        register({name: "responsible"});
        register({name: "commercial"});

        /*List Organization Client rest service */
        employee_service.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/enable`, setEmployeeList)
        employee_service.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/enable`, setResponsiblePersonList)
        service.get(getHostByAppName(IAM_NAME), `users/connected_user`, callbackConnectedUserRes)


    }, []);

    useEffect(
        () => {
            console.log(
                "in organization",
                assignment.organization
            );
            if (assignment.organization !== undefined) {
                setValue("organization", assignment.organization);
            }
        },
        [assignment]
    );

   // `${getHostByAppName(EMPLOYEE_NAME)}/${context}/${Id}`
    const callbackConnectedUserRes = data => {
        console.log("connected user : ", data);
        if(!data.administrator && data.roles.some((role)=> role.name=="COMMERCIAL") && !data.roles.some((role)=> role.name=="RESSOURCE_RH")){
            service.get(getHostByAppName(EMPLOYEE_NAME), `employees/byUser/${data.id}`, initCommercialValue)
            setSelectedCommercialDisabled(true);
        }else {
            employee_service.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/commercials`, setCommercialPersonList);
            setSelectedCommercialDisabled(false);
        }
    }

    const initCommercialValue = item => {
        console.log("init commercial seleted ", item)
        setValue("commercial", item.id);
        let commercial = {
            key: item.id,
            label: item.firstName + " " + item.lastName,
            value: item.id,
            object: item
        };
        setCommercialPersonList([commercial]);
        setSelectedCommercial(commercial);
        
    }

    const handleChangeEmployee = selectedOption => {
        console.log("assignent.employeeList", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
        } else {
            setValue("employee", undefined);
        }
    };

    const handleChangeResponsiblePerson = selectedOption => {
        console.log("assignent.responsiblePersonList", selectedOption);
        if (selectedOption !== null) {
            setValue("responsible", selectedOption.key);
        } else {
            setValue("responsible", undefined);
        }
    };

    const handleChangeCommercialPerson = selectedOption => {
        console.log("assignent.commercialPersonList", selectedOption);
        if (selectedOption !== null) {
            setValue("commercial", selectedOption.key);
        } else {
            setValue("commercial", undefined);
        }
        setSelectedCommercial(selectedOption);
    };

    return (
        <div className="pl-3">
            <Row>

                <Col>
                    <FormGroup>
                        <IntlMessages id="assignment.employee"/>
                        <AdaSelect
                            isClearable
                            name="employee"
                            errors={errors}
                            options={employeeList}
                            onChange={handleChangeEmployee}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    {/*<FormGroup>*/}
                    {/*    <IntlMessages id="assignment.responsible"/>*/}
                    {/*    <AdaSelect*/}
                    {/*        isClearable*/}
                    {/*        name="responsiblePerson"*/}
                    {/*        errors={errors}*/}
                    {/*        options={employeeList}*/}
                    {/*        onChange={handleChangeResponsiblePerson}*/}
                    {/*        innerRef={register}*/}
                    {/*        */}
                    {/*    />*/}
                    {/*</FormGroup>*/}
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="assignment.commercial"/>
                        <AdaSelect
                            isClearable
                            name="commercial"
                            errors={errors}
                            options={commercialPersionList}
                            onChange={handleChangeCommercialPerson}
                            innerRef={register}
                            value={selectedCommercial}
                            isDisabled={selectedCommercialDisabled}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
