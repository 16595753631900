import React, {useEffect, useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaSelect} from "@adaming/ada-react-component";
import * as specificService from "../../../service/employee.service";
import {ASSIGNMENT_NAME, EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import {Controller} from "react-hook-form";
import {getAllAssignementsForEmployee} from "../../../service/employee.service";

export default function OrderAssignment({
                                            register,
                                            setValue,
                                            errors,
                                            order,
                                            edit,
                                            control,
                                            dispatch,
                                            setAssignment
                                        }) {
    const [assignmentList, setAssignmentList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);


    useEffect(
        () => {
            /*List employee rest service */
            register({name: "assignment"});
            specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/enable`, setEmployeeList)
        },
        []
    );

    useEffect(
        () => {
           if(assignmentList.length){
               setValue("assignment", assignmentList[0].key);
               dispatch({assignment: assignmentList[0].key})
               setAssignment(assignmentList[0].object)
           }
        },
        [assignmentList]
    );

    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setSelectedEmployee(selectedOption.value);
            setValue("employee", selectedOption.key);
            setValue("assignment", null);
            setAssignment({})
            dispatch({client: selectedOption.key, assignment: null})
            specificService.getAllAssignementsForEmployee(getHostByAppName(ASSIGNMENT_NAME), `assignments/advanced_search/get?size=10&page=0`, selectedOption.key, setAssignmentList)
        } else {
            setValue("employee", null);
            setSelectedEmployee(null);
            setValue("assignment", null);
            setAssignmentList([])
            dispatch({employee: null, assignment: null})
            setAssignment({})
        }
    };

    const handleChangeAssignment = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("assignment", selectedOption);
        if (selectedOption !== null) {
            setValue("assignment", selectedOption.key);
            dispatch({assignment: selectedOption.key})
            setAssignment(selectedOption.object)
        } else {
            setValue("assignment", null);
            dispatch({assignment: null})
            setAssignment({})
        }
    };

    return (
        <div className="roe-card-body col-sm-12">

            <Row>
                <Col className="col-sm-3">
                    <IntlMessages id="action.employee"/>
                    <Controller
                        as={<AdaSelect name={"employeeAss"}/>}
                        name={"employeeAss"}
                        isClearable
                        errors={errors}
                        options={employeeList}
                        onChange={handleChangeEmployee}
                        control={control}
                        innerRef={register}
                    />
                </Col>

                {
                    assignmentList.length ?
                        <Col className="col-sm-3">
                            <FormGroup>
                                <IntlMessages id="assignment.update"/>
                                <Controller
                                    as={<AdaSelect name={"assignment"}/>}
                                    name={"assignment"}
                                    isClearable
                                    errors={errors}
                                    options={assignmentList}
                                    defaultValue={assignmentList[0]}
                                    onChange={handleChangeAssignment}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col> : selectedEmployee? <div>Aucun ordre de mission trouvé</div> : null
                }



            </Row>

        </div>
    );
}
