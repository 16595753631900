import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import {Controller, useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import * as specificService from "../../../service/employee.service";
import {CRM_NAME, EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import {AdaSelect, AdaButton} from "@adaming/ada-react-component";
import {AdaInputText} from "@adaming/ada-react-component";

export default function AdvancedSearch({handlerAdvancedSearch, handlerCancelAdvancedSearch}) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [clientGroupList, setClientGroupList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [object, setObject] = useState({
        "clientGroup" : null,
        "client": null,
        "entity": null,
        "employee": null,
        beginDate: null,
        endDate: null
    });


    useEffect(() => {
        specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
        service.getAllObj(getHostByAppName(CRM_NAME), `clients/groups`, setClientGroupList)
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
    }, [])

    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            setObject({...object, employee: selectedOption.key})
        } else {
            setValue("employee", null);
            setObject({...object, employee: null})
        }
    };

    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("client", selectedOption);
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setValue("entity", null);
            setObject({...object, client: selectedOption.key, entity: null})
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.key}`, setEntityList)
        } else {
            setValue("client", null);
            setValue("entity", null);
            setEntityList([])
            setObject({...object, client: null, entity: null})
        }
    };

    const handleChangeClientGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("clientGroup", selectedOption.key);
            setValue("client", null);
            console.log("clientGroup changed ",selectedOption.key )

            setObject({...object, clientGroup: selectedOption.key, group: null})

            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/findByGroup/${selectedOption.key}`, setClientList)

        } else {
            setValue("clientGroup", null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
            setObject({...object, clientGroup: null, client: null})
        }
    };

    const handleChangeEntity = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("entity", selectedOption);
        if (selectedOption !== null) {
            setValue("entity", selectedOption.key);
            setObject({...object, entity: selectedOption.key})
        } else {
            setValue("entity", null);
            setObject({...object, entity: null})
        }
    };

    const onSubmit = (data) => {
        console.log("data search", data)
        console.log("object search", object)
        handlerAdvancedSearch({...data, ...object});
    }

    const clear = () => {
        reset({"client": null, "entity": null, "employee": null, beginDate: null, endDate: null});
        setObject({})
        handlerCancelAdvancedSearch();
    }


    return (<div >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="assignment.client.group"/>
                                    <Controller
                                        as={<AdaSelect name={"clientGroup"}/>}
                                        name={"clientGroup"}
                                        isClearable
                                        errors={errors}
                                        options={clientGroupList}
                                        onChange={handleChangeClientGroup}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="assignment.client"/>
                                    <Controller
                                        as={<AdaSelect name={"client"}/>}
                                        name={"client"}
                                        isClearable
                                        errors={errors}
                                        options={clientList}
                                        onChange={handleChangeClient}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <FormGroup>
                                    <IntlMessages id="assignment.entity"/>
                                    <Controller
                                        as={<AdaSelect name={"entity"}/>}
                                        name={"entity"}
                                        isClearable
                                        errors={errors}
                                        options={entityList}
                                        onChange={handleChangeEntity}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="from.period"/>
                                    <AdaInputText
                                        type="date" style={{height:38}}
                                        name="beginDate"
                                        errors={errors}
                                        innerRef={register}
                                        onBlur={e => setObject({...object, beginDate: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="end.period"/>
                                    <AdaInputText style={{height:38}}
                                        type="date"
                                        name="endDate"
                                        errors={errors}
                                        innerRef={register}
                                        onBlur={e => setObject({...object, endDate: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="action.employee"/>
                                    <Controller
                                        as={<AdaSelect name={"employee"}/>}
                                        name={"employee"}
                                        isClearable
                                        errors={errors}
                                        options={employeeList}
                                        onChange={handleChangeEmployee}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="order.reference"/>
                                    </div>
                                    <AdaInputText
                                        type="text" style={{height:38}}
                                        name="reference"
                                        errors={errors}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="pt-3">
                        <Row >
                            <Col>
                            <FormGroup>
                                <AdaButton style={{height:38}} className="c-danger col-xl-5" onClick={() => clear()}>
                                    <IntlMessages id="action.common.cancel"/>
                                </AdaButton>
                                <AdaButton
                                    className="c-warning col-xl-6"
                                    type="submit"
                                    style={{marginLeft: 10, height:38}}
                                >
                                    <IntlMessages id="common.search"/>
                                </AdaButton>
                            </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    )
}