import React, {useEffect, useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import axios from "axios";
import AssignementDate from "../assignement.date";
import AssignmentTjm from "./assignement.tjm";
import AssignmentContractTypeOrder from "../assignment.order/assignment.contractTypeOrder";
import AssignmentSellPriceTable from "./assignment.sellPriceTable";
import AssignmentDiscountPriceTable from "./assignment.discountPriceTable";
import AssignmentOrganization from "./assignement.organization";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

export default function AssignmentCollab({
                                             register,
                                             setValue,
                                             errors,
                                             assignment,
                                             edit
                                         }) {

    useEffect(() => {
        register(
            {name: "designation"}
        );
        register(
            {name: "task"}
        );
    }, [])
    return (
        <div className="row ma-0">
            <div className="col-sm-12 ">
                <div className="roe-card-style">
                    <div className="roe-card-body col-sm-12">

                        <PageviewsChartWrapper>
                            <div className="page-view-chart-widget-card pl-4 mt-3 pt-3 pb-3 pr-5">

                                <Row className=" pb-3">
                                    <Col>
                                        <div>
                                            <span className="hash"># </span><IntlMessages id="assignment"/>
                                        </div>
                                    </Col>
                                </Row>

                                <AssignmentOrganization
                                    setValue={setValue}
                                    errors={errors}
                                    register={register}
                                    assignment={assignment}
                                    edit={edit}
                                />
                            </div>
                        </PageviewsChartWrapper>

                        <PageviewsChartWrapper>
                            <div className="page-view-chart-widget-card pl-4 mt-3 pt-3 pb-3 pr-5">
                                <Row className="pt-3">
                                    <Col>
                                        <div>
                                            <span className="hash"># </span><IntlMessages id="employee.mission.Type"/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pl-2">
                                    <Col>
                                        <AssignmentContractTypeOrder
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            assignment={assignment}
                                            edit={edit}
                                        />
                                    </Col>
                                </Row>
                                <Row className=" pl-4">
                                    <Col className="pt-2">
                                        <IntlMessages id="assignment.mission.description"/>
                                        <AdaInputText
                                            name="designation" style={{height:38}}
                                            errors={errors}
                                            innerRef={register}
                                            defaultValue={
                                                assignment !== undefined ? assignment.designation : ""
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className=" pl-4">
                                    <Col className="pt-2">
                                        <IntlMessages id="assignment.mission.task"/>
                                        <AdaInputText
                                            name="task" style={{height:38}}
                                            errors={errors}
                                            innerRef={register}
                                            defaultValue={
                                                assignment && assignment.id ? assignment.task : "Vos tâches informatiques vous seront remises dès votre arrivée"
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </PageviewsChartWrapper>

                        <PageviewsChartWrapper>
                            <div className="page-view-chart-widget-card pl-4 mt-3 pt-3 pb-3 pr-5">
                                <Row className="pt-3">
                                    <Col>
                                        <div>
                                            <span className="hash"># </span><IntlMessages
                                            id="assignment.price.and.sell"/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pl-3">
                                    <Col className="col-sm-6">

                                        <AssignmentSellPriceTable
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            assignment={assignment}
                                            edit={edit}
                                        />
                                    </Col>
                                    <Col className="col-sm-6 pl-5">
                                        <AssignmentDiscountPriceTable
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            assignment={assignment}
                                            edit={edit}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </PageviewsChartWrapper>


                    </div>
                </div>
            </div>
        </div>
    );
}
