import React, {useEffect, useState} from "react";
import {Row, Toast, ToastBody, ToastHeader} from "reactstrap";

import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import * as service from "../../../../service/crud.service";
import {EMPLOYEE_NAME, CRM_NAME, getHostByAppName} from "../../../../service/host";
import LatestOrgActivity from "../InfoDoughnutWidget"
import {buildingEnterprise, contacticon} from "../../../../helpers/constants";
import WrapperInformation from "../../../commun/wrapper";

export default function AssignmentDisplayClient({clientObject, type}) {

    const [client, setClient] = useState({});
    const [address, setAddress] = useState({});
    const [entity, setEntity] = useState({});
    const [contactsList, setContactsList] = useState([]);
    const [contactsOpList, setContactsOpList] = useState([]);
    const [contact, setContact] = useState({});
    const [contactOp, setContactOp] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const mapAddressResult = (addressList) => {
        console.log("in mapAddressResult", addressList)
        if (addressList !== undefined && addressList !== null && addressList.length > 0) {
            setAddress(addressList[0])
        }
    }

    useEffect(() => {
        setContactsList([]);
        setContactsOpList([]);
        if (clientObject !== undefined && clientObject !== null) {
            if (clientObject.client !== undefined && clientObject.client !== null) {
                service.getById(
                    getHostByAppName(CRM_NAME),
                    `clients`,
                    setClient,
                    setErrorMessage,
                    clientObject.client
                );

                service.get(
                    getHostByAppName(CRM_NAME),
                    `clients/${clientObject.client}/address`,
                    mapAddressResult, setErrorMessage);
            }

            if (clientObject.entity !== undefined && clientObject.entity !== null) {
                service.getById(
                    getHostByAppName(CRM_NAME),
                    `entitys`,
                    setEntity,
                    setErrorMessage,
                    clientObject.entity
                );
            }

            if (clientObject.contacts !== undefined && clientObject.contacts !== null && clientObject.contacts.length > 0) {
                clientObject.contacts.forEach(contactId => {
                    service.getById(
                        getHostByAppName(CRM_NAME),
                        `contacts`,
                        addContactToList,
                        setErrorMessage,
                        contactId
                    );
                })
            }

            if (clientObject.contactsOp !== undefined && clientObject.contactsOp !== null && clientObject.contactsOp.length > 0) {
                clientObject.contactsOp.forEach(contactId => {
                    service.getById(
                        getHostByAppName(CRM_NAME),
                        `contacts`,
                        addContactToListOp,
                        setErrorMessage,
                        contactId
                    );
                })
            }

        }
    }, [clientObject]);


    const addContactToList = (contact) => {
        setContact(contact);
    }

    const addContactToListOp = (contact) => {
        setContactOp(contact);
    }

    useEffect(() => {
        if (contact.id !== undefined) {
            setContactsList(contactsList.concat(contact));
        }
    }, [contact])

    useEffect(() => {
        if (contactOp.id !== undefined) {
            setContactsOpList(contactsOpList.concat(contactOp));
        }
    }, [contactOp])

    const showAddress = addressBilling => {
        console.log("in show address", addressBilling)
        if (addressBilling !== undefined) {
            return (
                (addressBilling.streetNumber ? addressBilling.streetNumber : "") +
                " " +
                (addressBilling.streetName ? addressBilling.streetName : "") +
                " " +
                (addressBilling.city ? addressBilling.city : "") +
                " " +
                (addressBilling.zipCode ? addressBilling.zipCode : "") + " " + (clientObject.complementaryAddress ? clientObject.complementaryAddress : "")
            );
        }
    };

    function getClientName() {
        let name = ""
        if (client && client.name)
            name = client.name;
        if (entity && entity.name)
            name = name + " / " + entity.name

        return name;
    }

    return (
        <div className="ptb-5">


            {clientObject && clientObject.client && <LatestOrgActivity
                name={getClientName()}
                icon={buildingEnterprise}
                title={client.name}
                description={client.description ? client.description : "ND"}
                site={client.web ? client.web : "ND"}
                telephone={client.contactInformation && client.contactInformation.phone ? client.contactInformation.phone : "ND"}
                email={client.contactInformation && client.contactInformation.email ? client.contactInformation.email : "ND"}
                fax={client.contactInformation && client.contactInformation.fax ? client.contactInformation.fax : "ND"}
                statut={client.enable === true ? "Activé" : "Désactivé"}
                owner={client.owner}
                activityArea={client.activityArea}
                address={showAddress(address)}
            />}

            {clientObject && clientObject.client && <div className="roe-card-body col-sm-12">
                <Row>
                    <Col>

                        <Row className="pt-4">
                            <Col>
                                <div>
                                    <span className="hash"># </span>Contacts de facturations
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            {client !== undefined &&
                            contactsList.length > 0 &&
                            contactsList.map((contactObj, key) => (
                                    <Col key={key}>
                                        < WrapperInformation title={contactObj.firstName + " " + contactObj.lastName}
                                                             icon={contacticon}
                                                             informationLigne1={contactObj.contactInformation.email}
                                                             informationLigne2={contactObj.contactInformation.mobile}
                                                             onclickDetails={() => console.log("no editable")}
                                                             showFooter={false}/>
                                    </Col>
                                )
                            )
                            }

                        </Row>
                    </Col>
                    <Col>

                        <Row className="pt-4">
                            <Col>
                                <div>
                                    <span className="hash"># </span>Contacts opérationnels
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            {client !== undefined &&
                            contactsOpList.length > 0 &&
                            contactsOpList.map((contactObjOp, key) => (
                                    <Col key={key}>
                                        < WrapperInformation title={contactObjOp.firstName + " " + contactObjOp.lastName}
                                                             icon={contacticon}
                                                             informationLigne1={contactObjOp.contactInformation.email}
                                                             informationLigne2={contactObjOp.contactInformation.mobile}
                                                             onclickDetails={() => console.log("no editable")}
                                                             showFooter={false}/>
                                    </Col>
                                )
                            )
                            }

                        </Row>

                    </Col>

                </Row>
            </div>}

        </div>
    );
}
