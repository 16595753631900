import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {FormGroup, Input, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import { useForm} from 'react-hook-form';



export default function AssignmentAddLineDiscountModal(props) {
    const {register, handleSubmit, errors, setValue} = useForm();

    const [errorMessage, setErrorMessage] = useState("")

    const onSubmit = data => {
        console.log("in modal submit", data);
        if (!data.discount || data.discount == '' || data.discount == '0') {
            setErrorMessage("Nombre de jours obligatoire")
        } else  {
            props.processActions(data);
            props.onHide();
            setErrorMessage("")
        }
    };


    return (
        <Modal
            {...props}
            size="l"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">

                    <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}><IntlMessages id="assignment.discount"/></h4>
                </Modal.Title>
            </Modal.Header>
            <div>
                <div>
                    <div className="row ma-0">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body col-sm-12">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col className="col-sm-6">
                                                <FormGroup>
                                                    <IntlMessages id="assignment.date.start"/>
                                                    <AdaInputText
                                                        name="beginAtDiscount"
                                                        type="date"
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <IntlMessages id="assignment.discounts"/>
                                                <AdaInputText
                                                    name="discount"
                                                    type="number"
                                                    placeholder="0.00"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="pt-3">
                                            <Col className="col-sm-5">
                                                <div className="display-6 c-text-danger">
                                                    {errorMessage}
                                                </div>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-danger w-100"  onClick={props.onHide}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>

                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-warning w-100" type="submit">
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal.Footer/>
        </Modal>
    );
}
